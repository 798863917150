<template>
  <div>
    <header class="header">
      <div class="header-text">付款给 {{ payOrderInfo.mch_short_name }}</div>
      <div class="header-img">
        <img :src="avatar ? avatar : icon_member_default" alt="">
      </div>
    </header>
    <div class="plus-input">
      <!-- ￥字符 货币的符号-->
      <div class="S">
        <img src="../../assets/icon/S.svg" alt="">
      </div>

      <!-- 输入框光标 -->
      <!-- <div class="ttt">
        <div
          class="input-c-div"
          style="background: #07c160"
        ></div>
      </div> -->

      <!-- 手写输入框 -->
      <div class="input-c">
        <div class="input-c-div-1">{{ amount }}</div>
        <!-- 数字金额后边的光标 -->
        <!-- <div class="input-c-div" style="background:#07c160"></div> -->
      </div>

    </div>

    <!-- 备注板块 ，目前不需要添加备注，隐藏-->
    <div class="remark-k">
      <div class="remark">
        <div class="title">商品名称:{{ payOrderInfo.title }}</div>
      </div>
      <div v-show="payOrderInfo.remark" class="remark">
        <div class="remark-hui">说明:{{ payOrderInfo.remark }}</div>
      </div>
    </div>
    <!-- dialog 对话框 目前不需要添加备注，隐藏-->
    <!-- <MyDialog
      v-show="myDialogState"
      @myDialogStateFn="myDialogStateFn"
      :remark="remark"
    >
    </MyDialog> -->

    <!-- 键盘板块 目前不需要键盘 隐藏 -->
    <!-- <div class="keyboard-plus" v-if="isAllowModifyAmount">
      <Keyboard
        @delTheAmount="delTheAmount"
        @conceal="conceal"
        @enterTheAmount="enterTheAmount"
        @payment="payment"
        :money="money"
        :concealSate="concealSate"
        :typeColor="typeColor[payType]"
      ></Keyboard>
    </div> -->

    <!-- jeepay中，付款的点击事件 由 payment 修改为 pay  -->
    <!-- jeepay中，付款页面是唯一的，颜色不在需要v-bind，去掉即可 -->
    <!-- <div class="bnt-pay" v-if="!isAllowModifyAmount"> -->
    <div class="bnt-pay">
      <div
        class="bnt-pay-text"
        style="background-color:#07c160"
        @click="payDo"
      >
        付款
      </div>
    </div>
  </div>
</template>

<script>
// import MyDialog from "./../dialog/dialog";  // 添加备注弹出的对话框
// import Keyboard from "./../keyboard/keyboard";  // 手写键盘
import { getPayPackage, getPayOrderInfo } from '@/api/api'
import config from '@/config'
export default {
  // 注册备注对话框，和 手写键盘组件，由于这里是直接掉起支付事件，所以目前不应用
  // components: { MyDialog, Keyboard },
  data() {
    return {
      payToken: '',
      merchantName: 'jeepay', // 付款的商户默认
      avatar: require('../../assets/icon/wx.svg'), // 商户头像默认
      amount: '', // 支付金额默认
      resData: null,
      wxImg: require('../../assets/icon/wx.svg'), // 微信支付图片
      payOrderInfo: {
        'fee': '',
        'mch_short_name': '',
        'remark': ''

      } // 订单信息
    }
  },

  mounted() {
    this.payToken = this.$route.params[config.urlTokenName]
    console.log('正在跳转', this.payToken)

    this.setPayOrderInfo(true) // 获取订单信息 & 调起支付插件
  },

  methods: {

    setPayOrderInfo(isAutoPay) {
      const that = this

      const toast = this.$toast.loading('加载中...', {
        coverColor: 'rgba(0,0,0,0.5)'
      })

      getPayOrderInfo(this.payToken).then(res => {
        this.payOrderInfo = res
        this.amount = (+this.payOrderInfo.fee / 100).toFixed(2)

        if (isAutoPay) {
          // that.pay()
        }
        toast.hide()
      }).catch(res => {
        that.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.message }})
        toast.hide()
      })
    },

    // 支付事件
    payDo() {
      const toast = this.$toast.loading('加载中...', {
        coverColor: 'rgba(0,0,0,0.5)'
      })
      // 该函数执行效果慢
      const that = this
      getPayPackage(this.amount, this.payToken).then(res => {
        toast.hide()
        if (!res.prepay_id) {
          this.$router.push({ name: config.errorPageRouteName, params: { errInfo: '订单已过期，请重新扫码支付' }})
          return
        }
        this.resData = res
        if (typeof WeixinJSBridge === 'undefined') {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady, false)
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady)
            document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady)
          }
        } else {
          that.onBridgeReady()
        }
      }).catch(res => {
        toast.hide()
        that.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.msg }})
      })
    },

    /* 唤醒微信支付*/
    onBridgeReady() {
      const that = this
      const payInfo = this.resData.jsapi_info
      console.log(payInfo)

      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', payInfo,
        function(res) {
          console.log(res)
          that.$router.push({ name: 'Success', params: { succInfo: '' }})
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            // //重定向
            that.$router.push({ name: 'Success', params: { succInfo: '' }})
            window.WeixinJSBridge.call('closeWindow')
          }
          if (res.err_msg === 'get_brand_wcpay_request:cancel') {
            this.$dialog({
              content: '取消支付'
            })
            window.WeixinJSBridge.call('closeWindow')
          }
          if (res.err_msg === 'get_brand_wcpay_request:fail') {
            console.log('支付失败:', res)
            // that.$router.push({ name: config.errorPageRouteName, params: { errInfo: '支付失败' }})
            window.WeixinJSBridge.call('closeWindow')
          }
          if (res.err_msg !== '') {
            console.log('支付失败:', res)
            // that.$router.push({ name: config.errorPageRouteName, params: { errInfo: '支付参数错误' }})
            window.WeixinJSBridge.call('closeWindow')
          }
        }
      )
    }

  }

}
</script>
<style lang="css" scoped>
 @import './pay.css';
</style>
